import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import "../sass/style.scss";
import { Link } from "gatsby";

import MainLayout from "./../components/layouts/MainLayout";
import withAnalytics from "../components/hoc/withAnalytics";
import { navLinks404 } from "../content/nav";

function Page404() {
  return (
    <>
      <MainLayout navLinks={navLinks404} footer={false}>
        <main>
          <div className="screen-center text-center">
            <h3>Oops, I couldn't find this page :(</h3>
            <p>
              Let's go to my <Link to="/">Home</Link> page for awesomeness
              instead.
            </p>
            <Link to="/" className="btn btn-default">
              Home
            </Link>
          </div>
        </main>
      </MainLayout>
    </>
  );
}

export default withAnalytics(Page404);
